import React from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  const __confirmPasswordHandler = async (values: any) => {
    const { password, confirmPassword } = values;
    console.log("Password==>", password, confirmPassword);

    if (password !== confirmPassword) {
      // Set validation error for confirmPassword field
      form.setFields([
        {
          name: "confirmPassword",
          errors: ["Password and Confirm Password must match."],
        },
      ]);
    } else {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}users/set-password `,
          {
            password: password,
          }
        );
        console.log("API Resp==>", resp.data.user);
        const userData = resp?.data?.user;
        localStorage.setItem("userData", JSON.stringify(userData));
        if (resp.data.token) {
          localStorage.setItem("token", resp.data.token);
          navigate("/brands");
          // localStorage.removeItem("token");
          // localStorage.removeItem("reset_token");
        }
        // navigate("/");
      } catch (e) {
        console.log("Error while reseting password", e);
      }
    }
  };
  return (
    <Modal open={true} closable={false} footer={null} centered>
      {/* <Tabs defaultActiveKey="1" centered items={items} /> */}
      <>
        <Form form={form} onFinish={__confirmPasswordHandler} layout="vertical">
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "This field is required." },
                  // {
                  //   required: true,
                  //   type: "email",
                  //   message: "The input is not valid E-mail!",
                  // },
                ]}
              >
                <Input placeholder="*******" type="password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "This field is required." },
                  // {
                  //   required: true,
                  //   type: "email",
                  //   message: "The input is not valid E-mail!",
                  // },
                ]}
              >
                <Input placeholder="*******" type="password" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Button
                className="formBtm"
                // disabled={auth?.isLogging}
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
}
