import React from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  InputNumber,
  Spin,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyOtp } from "../../store/slices/authSlice";

export default function VerifyOtp() {
  const location = useLocation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get("source");

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();

  const email: any = location.state?.email;
  console.log("Props in verify otp ===>", email, source);
  // const email = location.state && location?.state.email;
  const __otpHandler = async (otp: any) => {
    console.log(otp.otp);
    const apiPayload = {
      email: email?.email,
      code: otp.otp.toString(),
    };

    console.log("here121", apiPayload);

    const result = await dispatch(verifyOtp(apiPayload));
    console.log("Result ==>", result);

    if (result?.payload?.status === true) {
      navigate("/verify-otp", { state: { email } });

      alert("Otp verified Successfully.");
      messageApi.open({
        type: "success",
        content: "Otp Sent via mail. Please verify!",
      });
      if (source == "forget-password") {
        navigate("/reset-password");
      } else {
        navigate("/new-password");
      }
    } else {
      alert("Invalid Otp.");

      messageApi.open({
        type: "error",
        content: result?.payload?.message || "Something went wrong.",
      });
    }
  };
  return (
    <>
      <Modal open={true} closable={false} footer={null} centered>
        {/* <Tabs defaultActiveKey="1" centered items={items} /> */}
        <>
          <Form onFinish={__otpHandler} layout="vertical">
            <Row>
              <Col span={6}></Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    { required: true, message: "This field is required." },
                    {
                      pattern: /^\d{4}$/, // Ensure it is a 4-digit string
                      message: "Please enter a 4-digit OTP.",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="1234"
                    style={{ width: "100%" }}
                    maxLength={4}
                    min={0}
                    max={9999}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}></Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div
                  onClick={() => navigate(`/${source}`)}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "-15px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  <p style={{ color: "white" }}>Did'nt get OTP? Resend</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={6}></Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Button
                  className="formBtm"
                  // disabled={auth?.isLogging}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Modal>
    </>
  );
}
