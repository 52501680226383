import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isCategoryLoading: false,
    categoryResults: '',
};


export const getCategories = createAsyncThunk('category/getAll', async (data: any, thunkAPI: any) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}categories?perPage=9999999&sortBy=sort_no&sortOrder=ASC`)

        return {
            response: response?.data
        };
    } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
}
);

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.isCategoryLoading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isCategoryLoading = false;
                state.categoryResults = action.payload.response.records.data
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isCategoryLoading = false;
            });
    },
});

export default categorySlice.reducer;
