import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isBogoLoading: false,
  bogoResults: "",
  isBogoByLocationLoading: false,
  bogoByLocations: [],
  daySlider: 1,
  filterValue: "",
};

export const getBogos = createAsyncThunk(
  "bogo/getAll",
  async (data: any, thunkAPI: any) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user-bogos`, {
            params:{
                perPage:9999999,
                ...data
            }
        })

      return {
        response: response?.data,
        filters: data,
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getBogosByLocation = createAsyncThunk(
  "bogo/getAllByLocations",
  async (data: any, thunkAPI: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}bogos`,
        {
          params: { perPage: 9999999, ...data },
        }
      );

      return {
        response: response?.data,
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const bogoSlice = createSlice({
  name: "bogo",
  initialState,
  reducers: {
    resetBogoData: (state) => {
      state.bogoResults = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBogos.pending, (state) => {
        state.isBogoLoading = true;
      })
      .addCase(getBogos.fulfilled, (state, action) => {
        state.isBogoLoading = false;
        state.bogoResults = action.payload.response.records.data;
        state.filterValue = action.payload.filters;
      })
      .addCase(getBogos.rejected, (state, action) => {
        state.isBogoLoading = false;
      })
      .addCase(getBogosByLocation.pending, (state) => {
        state.isBogoByLocationLoading = true;
      })
      .addCase(getBogosByLocation.fulfilled, (state, action) => {
        state.isBogoByLocationLoading = false;
        state.bogoByLocations = action.payload.response.records.data;
      })
      .addCase(getBogosByLocation.rejected, (state, action) => {
        state.isBogoByLocationLoading = false;
      });
  },
});
export const { resetBogoData } = bogoSlice.actions;
export default bogoSlice.reducer;
