import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isVendorLoading: false,
    vendorResults: '',
};


export const getVendors = createAsyncThunk('vendors/getAll', async (data: any, thunkAPI: any) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}vendors?perPage=9999999&sortBy=name&sortOrder=ASC`)

        return {
            response: response?.data
        };
    } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
}
);

export const authSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        // isLoggingReset: (state) => {
        //     state.isLogging = false;
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVendors.pending, (state) => {
                state.isVendorLoading = true;
            })
            .addCase(getVendors.fulfilled, (state, action) => {
                state.isVendorLoading = false;
                state.vendorResults = action.payload.response.records.data
            })
            .addCase(getVendors.rejected, (state, action) => {
                state.isVendorLoading = false;
            });
    },
});

// export const { isLoggingReset } = authSlice.actions;
export default authSlice.reducer;
