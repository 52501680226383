import { configureStore } from '@reduxjs/toolkit';
import polygonSlice from './slices/polygonSlice';
import authSlice from './slices/authSlice';
import vendorSlice from './slices/vendorSlice';
import categorySlice from './slices/categorySlice';
import userSlice from './slices/userSlice';
import bogoSlice from './slices/bogoSlice';
import locationSlice from './slices/locationSlice';

const store = configureStore({
  reducer: {
    polygon:polygonSlice,
    auth:authSlice,
    vendors:vendorSlice,
    user:userSlice,
    category:categorySlice,
    bogo:bogoSlice,
    location:locationSlice,
  },
});

export default store;
