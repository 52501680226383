import React, { useState } from "react";
import { Modal, Form, Input, Button, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserPassword } from "../../store/slices/userSlice";
interface UpdatePasswordModalProps {
  visible: boolean;
  onCancel: () => void;
}

const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const __confirmPasswordHandler = async (values: any) => {
    console.log("Values==>", values);
    try {
      const result = await dispatch(updateUserPassword(values));
      console.log("Values==> Result", result);
      if (result.payload.status == 200) {
        alert("Password Changed Successfully");
        messageApi.open({
          type: "success",
          content: "Password Changed Successfully.",
        });
      } else {
        alert(result.payload.error);
        messageApi.open({
          type: "error",
          content: "Something went wrong",
        });
      }
      onCancel();
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} onFinish={__confirmPasswordHandler} layout="vertical">
        <Row>
          <Col span={6}></Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label="Current Password"
              name="oldPassword"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input placeholder="*******" type="password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input placeholder="*******" type="password" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}></Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Button
              className="formBtm"
              // disabled={auth?.isLogging}
              htmlType="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
