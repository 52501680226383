import { useState } from "react";
import { Modal, Drawer, Spin, Row, Col, Avatar, Space } from 'antd';
import { useSelector } from "react-redux";
import { DoubleRightOutlined } from '@ant-design/icons';
import facebookIcon from '../../assets/icons/facebook.svg';
import whatsappIcon from '../../assets/icons/whatsapp.svg';
import instaIcon from '../../assets/icons/instagram.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import moment from 'moment'
import Countdown from 'react-countdown';
import { openGoogleMap } from "../../utls/helper";
// import GoogleMapSearch from "./GoogleMapSearch";

type BogoInfoProps = {
    isOpen: boolean;
    handleDrawerOpen: () => void;
    selectedMarker: any;
    closeBogoModal: () => void;
    openAddressSearch: (selectedInfo: any) => void;
    isLocationServiceOpen: boolean;
};
const BogoInfo: React.FC<BogoInfoProps> = ({
    isOpen,
    handleDrawerOpen,
    selectedMarker,
    closeBogoModal,
    openAddressSearch,
    isLocationServiceOpen
}) => {
    const [isOpenBogoModal, setIsOpenBogoModal] = useState(false)
    const [isOpenLocationModal, setIsOpenLocationModal] = useState(false)
    const [openBogo, setOpenBogo] = useState<any>('')
    const bogo = useSelector((state: any) => state.bogo);
    const locationSlice = useSelector((state: any) => state.location);

    const BogoInfo = () => {
        return (
            <>
                <Drawer
                    title={selectedMarker?.Location?.name || 'N/A'}
                    placement={'left'}
                    maskClosable={true}
                    onClose={() => closeBogoModal()}
                    open={isOpen}
                >
                    <Row>
                        <Col span={24}>
                            <img src={process.env.REACT_APP_BASE_URL?.replace(/\/api\/v1\/?$/, '/') + selectedMarker?.Location?.image_path || window.location.origin + '/default.jpeg'} style={{ width: '100%' }} alt="img" />
                        </Col>
                    </Row>
                    {!bogo?.isBogoLoading ?
                        <>
                            {bogo?.bogoResults && bogo?.bogoResults.map((e: any, i: number) =>
                                selectedMarker?.location_id === e?.location_id &&
                                <Row key={`bogoInfo${i}`} className="bogoRows" onClick={() => { closeBogoModal(); setIsOpenBogoModal(true); setOpenBogo(e) }}>
                                    <Col span={5}>
                                        <Avatar size={50} src={process.env.REACT_APP_BASE_URL?.replace(/\/api\/v1\/?$/, '/') + e?.image_path} />
                                    </Col>
                                    <Col span={19} style={{ textAlign: 'left', display: 'flex' }}>
                                        <span className="bogoListBox">
                                            <span className="leftContent">
                                                <strong>{moment(e?.start_time).format("dddd, MMMM DD")}</strong> <br />
                                                <strong>{moment(e?.start_time).format("h:mm a")} to {moment(e?.end_time).format("h:mm a")} <br /> {e?.Vendor?.name || ''}</strong>
                                            </span>
                                            <DoubleRightOutlined style={{ float: 'right' }} />
                                        </span>
                                    </Col>
                                </Row>
                            )}
                        </>
                        :
                        <Spin />
                    }
                </Drawer>
            </>
        )
    }

    const Completionist = () => <div className="timerBox blinking-text">
        <span className="number">0 Day</span>
        <span className="number">:</span>
        <span className="number">0 Hr</span>
        <span className="number">:</span>
        <span className="number">0 Min</span>
        {/* <span className="number">:</span>
        <span className="number">0</span>
        <span className="number">:</span>
        <span className="number">0</span> */}
    </div>;

    const countDownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
        if (total) {
            // Render a countdown
            return (
                <div className="timerBox">
                    <span className="startsInTime">Starts In</span><br />
                    {days > 0 &&
                        <>
                            <span className="number">{days} {days > 1 ? 'Days' : 'Day'}</span>
                            <span className="number">:</span>
                        </>
                    }
                    <span className="number">{hours} {hours > 1 ? 'Hrs' : 'Hr'}</span>
                    <span className="number">:</span>
                    <span className="number">{minutes} {minutes > 1 ? 'Mins' : 'Min'}</span>
                    {/* <span className="number">:</span>
                    <span className="number">{seconds}</span> */}
                </div>
            );
        } else {
            // Render a finished state
            return <Completionist />;
        }
    };

    const endscountDownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
        if (total) {
            // Render a countdown
            return (
                <div className="timerBox">
                    <span className="startsInTime">Ends In</span>
                    {/* <span className="number">{days}</span>
                    <span className="number">:</span> */}
                    <span className="number">{hours} Hrs</span>
                    <span className="number">:</span>
                    <span className="number">{minutes} Mins</span>
                    {/* <span className="number">:</span>
                    <span className="number">{seconds}</span> */}
                </div>
            );
        } else {
            // Render a finished state
            return <Completionist />;
        }
    };

    const redirectToGoogleMap = (destination: any) => {
        if (isLocationServiceOpen) {
            openGoogleMap({ lat: locationSlice?.userGeoLocationInfo?.lat, lng: locationSlice?.userGeoLocationInfo?.lng }, { lat: destination?.Location?.lat, lng: destination?.Location?.lng })
            // window.open(`https://www.google.com/maps/dir/?api=1&origin=${locationSlice?.userGeoLocationInfo?.lat},${locationSlice?.userGeoLocationInfo?.lng}&destination=${destination?.Location?.lat},${destination?.Location?.lng}`, '_blank');
        } else {
            handleDrawerOpen();
        }
    }

    const BogoModal = () => {
        return (
            <>
                <Modal open={isOpenBogoModal} onCancel={() => setIsOpenBogoModal(false)} closable={true} footer={null} centered className="bogoDetail">
                    <Row>
                        <Col span={10} className="leftDetail">
                            <div className="bogoModalLeftContent">
                                <img src={process.env.REACT_APP_BASE_URL?.replace(/\/api\/v1\/?$/, '/') + openBogo?.image_path || 'public/default.jpeg'} alt='logo' />
                                <h4>Location Address</h4>
                                <p>{openBogo?.Location?.address || 'N/A'}</p>
                                {/\S/.test(openBogo?.Location?.address_2) &&
                                    <>
                                        <h4>Location Address 2</h4>
                                        <p>{openBogo?.Location?.address_2 || 'N/A'}</p>
                                    </>
                                }
                            </div>
                            {/* <div>
                                <Space size="small" align="center" className="bogoModalSocialBox socialIconsSection">
                                    <img className="socialShareIcons" src={facebookIcon} alt="" />
                                    <img className="socialShareIcons" src={whatsappIcon} alt="" />
                                    <img className="socialShareIcons" src={instaIcon} alt="" />
                                    <img className="socialShareIcons" src={twitterIcon} alt="" />
                                </Space>
                            </div> */}

                        </Col>
                        <Col span={14} className="rightDetail">
                            <h3>{openBogo?.Vendor?.name || 'Unknown Vendor'}</h3>
                            <p style={{ fontSize: '11px' }}>Buy One Get One</p>
                            <h4>{openBogo?.Category?.name || 'Unknown Vendor'}</h4>
                            <h4 style={{ marginTop: '20px' }}>Available at <br /><span style={{ color: '#1677ff' }}>{openBogo?.Location?.name}</span></h4>
                            {(new Date()) > new Date(openBogo.start_time) ?
                                <Countdown date={new Date(openBogo?.end_time)} renderer={endscountDownRenderer} />
                                :
                                <Countdown date={new Date(openBogo?.start_time)} renderer={countDownRenderer} />
                            }
                            <p style={{ fontSize: '11px' }}>
                                {moment(openBogo?.start_time).format("h:mm A")} to {moment(openBogo?.end_time).format("h:mm A")}
                            </p>

                        </Col>
                        <Col span={24} className="rightDetail" style={{ padding: '0', marginTop: '0px' }}>
                            <div className="bogoLetsGoBtn" onClick={() => {
                                // setIsOpenLocationModal(true);
                                setIsOpenBogoModal(false);
                                openAddressSearch(openBogo);
                                redirectToGoogleMap(openBogo);
                                // handleDrawerOpen();
                            }}>
                                <p>Buy One Get One</p>
                                <h3>LET'S GO</h3>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </>
        )
    }

    return (
        <>
            <BogoModal />
            <BogoInfo />
        </>
    )
}

export default BogoInfo;
