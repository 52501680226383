import { useEffect, useRef, useState } from "react";
import {
  Button,
  Select,
  Input,
  Drawer,
  Form,
  Row,
  Col,
  Slider,
  FormInstance,
  message,
} from "antd";
import {
  MenuFoldOutlined,
  LogoutOutlined,
  PushpinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";

import { getCategories } from "../../store/slices/categorySlice";
import { getVendors } from "../../store/slices/vendorSlice";
import { getBogos, resetBogoData } from "../../store/slices/bogoSlice";
import { setUserGeoLocationInfo } from "../../store/slices/locationSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import flashIcon from "../../assets/icons/basic-green.svg";
import moment from "moment";
import axios from "axios";

import { updateUser } from "../../store/slices/userSlice";
import UpdatePasswordModal from "./UpdatePassowrdModal";
import { toast } from "react-toastify";

const Dashboard = (props: {
  geoLoader: any;
  dispalyLocationServiceAlert: any;
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");

  const { dispalyLocationServiceAlert, geoLoader } = props;
  const dispatch = useDispatch<any>();
  const [isOpen, setIsOpen] = useState(false);
  // const [geoLocationLoader, setGeoLocationLoader] = useState(false);
  const [form] = Form.useForm();
  const [selectedDuration, setSelectedDuration] = useState(7);
  const navigate = useNavigate();
  const { Option, OptGroup } = Select;
  const category = useSelector((state: any) => state.category);
  const vendors = useSelector((state: any) => state.vendors);
  const locationSlice = useSelector((state: any) => state.location);
  const userData = JSON.parse(localStorage.getItem("userData") || "");

  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setIsOpen(false);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  const setUserInitialValues = () => {
    if (userData) {
      setUserEmail(userData.email);
      setUserMobile(userData.mobile);
    }
  };

  const defaultValueCategoriesId = userData?.Categories?.map((value: any) => {
    return value.id;
  });
  const defaultVendorsIds = userData?.Vendors?.map((value: any) => {
    return value.id;
  });

  const getDurationEndingDate = (duration: number) => {
    let someDate = new Date();
    let numberOfDaysToAdd = duration;
    return someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  };
  const updateProfileHandler = async (values: any) => {
    const result = await dispatch(updateUser(values));

    const responseData = result.payload.response.record;
    if (result.payload.status == 200) {
      const updatedUserData = {
        id: responseData.id,
        email: responseData.email,
        mobile: responseData.mobile,
        address: responseData.address,
        lat: responseData.lat,
        lng: responseData.lng,
        created_at: responseData.created_at,
        updated_at: responseData.updated_at,
        deleted_at: responseData.deleted_at,
      };
      const userDataString = JSON.stringify(updatedUserData);
      localStorage.setItem("userData", userDataString);
      messageApi.open({
        type: "success",
        content: "Logged in successfully.",
      });
      setShowProfile(false);
      alert("User Data Updated Successfully");
      setIsOpen(false);
    } else {
      messageApi.open({
        type: "error",
        content: "Something went wrong.",
      });
      setShowProfile(false);
      alert("Something went wrong");
    }
  };

  const logoutHandler = () => {
    dispatch(resetBogoData());
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };

  useEffect(() => {
    // const selectedVendors: string = defaultVendorsIds.join(",");
    // const selectedCategories: string = defaultValueCategoriesId.join(",");

    localStorage.getItem("token") &&
      dispatch(
        getBogos({
          end_date: moment(
            new Date(getDurationEndingDate(selectedDuration))
          ).format("YYYY-MM-DD 00:00"),
          start_date: moment(new Date()).format("YYYY-MM-DD 00:00"),
          // vendor_ids:selectedVendors,
          // category_id:selectedCategories
        })
      );

    dispatch(getCategories({}));
    setUserInitialValues();
    dispatch(getVendors({}));
  }, [dispatch]);

  const __applySearch = async (values: any) => {
    const selectedVendors: any = values?.vendor_id
      ? values?.vendor_id.join(",")
      : null;
    const selectedCategories: any = values?.category_id
      ? values?.category_id.join(",")
      : null;

    let result = getDurationEndingDate(values.duration);
    setIsOpen(false);
    // setSelectedVendor(uniqueVendorsArray);
    setSelectedDuration(values.duration);

    const searchQuery = {
      end_date: moment(new Date(result)).format("YYYY-MM-DD 00:00"),
      start_date: moment(new Date()).format("YYYY-MM-DD 00:00"),
    };

    selectedVendors &&
      Object.assign(searchQuery, { vendor_ids: selectedVendors });
    selectedCategories &&
      Object.assign(searchQuery, { category_ids: selectedCategories });

    await dispatch(getBogos(searchQuery));
  };

  const FilterBar = () => {
    const _getUserCurrentLocation = async () => {
      // setGeoLocationLoader(true);
      if (geoLoader) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              const geocoder = new window.google.maps.Geocoder();
              const location = new window.google.maps.LatLng(
                latitude,
                longitude
              );
              geocoder.geocode({ location }, (results, status) => {
                if (status === "OK" && results) {
                  dispatch(
                    setUserGeoLocationInfo({
                      lat: latitude,
                      lng: longitude,
                      address: results[0].formatted_address,
                    })
                  );
                } else {
                  console.error("Geocoder failed:", status);
                }
                // setGeoLocationLoader(false);
              });
            },
            (error) => {
              // setGeoLocationLoader(false);
              dispalyLocationServiceAlert(true);
            }
          );
        }
      }
    };

    return (
      <>
        <Drawer
          title={showProfile ? "Edit Your Profile" : "Apply Filters"}
          placement={"left"}
          closable={true}
          maskClosable={true}
          onClose={() => setIsOpen(false)}
          open={isOpen}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <div>
              {showProfile ? (
                <Form
                  onFinish={updateProfileHandler}
                  layout="vertical"
                  initialValues={{ email: userEmail, mobile: userMobile }}
                >
                  <Row>
                    <Col span={2}></Col>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "This field is required.",
                          },
                          {
                            required: true,
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input placeholder="abc@xyz.com" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}></Col>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                      <Form.Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "This field is required.",
                          },
                        ]}
                      >
                        <Input placeholder="12313123123123" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}></Col>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                      <div
                        onClick={() => showModal()}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "-20px",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <p style={{ color: "white" }}>Change Password</p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={2}></Col>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                      <Button
                        className="formBtm"
                        // disabled={auth?.isLogging}
                        htmlType="submit"
                      >
                        Update Profile
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}></Col>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                      <Button
                        className="formBtm"
                        // disabled={auth?.isLogging}
                        onClick={() => deleteUser()}
                      >
                        Delete Account
                      </Button>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Form
                  form={form}
                  name="basic"
                  onFinish={__applySearch}
                  layout="vertical"
                  initialValues={{
                    // vendor_id: null,
                    duration: selectedDuration,
                    // category_id: defaultValueCategoriesId,
                  }}
                  className="dashboardForm"
                >
                  <Row>
                    <Col span={24}>
                      <label className="formLabel">
                        Select <strong>Brands</strong>
                      </label>
                      <Form.Item name="vendor_id">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Search Brands"
                          optionLabelProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <OptGroup label="Your Brands">
                            {vendors.vendorResults &&
                              vendors.vendorResults.map((e: any, i: any) => {
                                return (
                                  defaultVendorsIds?.includes(e.id) && (
                                    <Option
                                      key={`vendor${e.id}`}
                                      value={e.id}
                                      label={e.name}
                                    >
                                      {e.name}
                                    </Option>
                                  )
                                );
                              })}
                          </OptGroup>
                          <OptGroup label="Other Brands">
                            {vendors.vendorResults &&
                              vendors.vendorResults.map((e: any, i: any) => {
                                return (
                                  !defaultVendorsIds?.includes(e.id) && (
                                    <Option
                                      key={`vendor${e.id}`}
                                      value={e.id}
                                      label={e.name}
                                    >
                                      {e.name}
                                    </Option>
                                  )
                                );
                              })}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <label className="formLabel">
                        Select <strong>Categories</strong>
                      </label>
                      <Form.Item name="category_id">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Search Categories"
                          optionLabelProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <OptGroup label="Your Categories">
                            {category.categoryResults &&
                              category.categoryResults.map((e: any, i: any) => {
                                return (
                                  defaultValueCategoriesId?.includes(e.id) && (
                                    <Option
                                      key={`category${e.id}`}
                                      value={e.id}
                                      label={e.name}
                                    >
                                      {e.name}
                                    </Option>
                                  )
                                );
                              })}
                          </OptGroup>
                          <OptGroup label="Other Categories">
                            {category.categoryResults &&
                              category.categoryResults.map((e: any, i: any) => {
                                return (
                                  !defaultValueCategoriesId?.includes(e.id) && (
                                    <Option
                                      key={`category${e.id}`}
                                      value={e.id}
                                      label={e.name}
                                    >
                                      {e.name}
                                    </Option>
                                  )
                                );
                              })}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }}>
                      <Form.Item label={`Select Days`} name="duration">
                        <Slider max={14} min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Button className="formBtm" htmlType="submit">
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
            <div className="dashboardBottom ">
              <Row>
                <Col
                  span={11}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowProfile(true)}
                  >
                    {userData?.email}
                  </span>
                </Col>
                <Col span={6}>
                  <div className="rewardsPoints" style={{ marginLeft: "25px" }}>
                    1,000 <br />
                    Pts
                  </div>
                </Col>
                <Col span={7}>
                  <Button className="formBtm" htmlType="submit">
                    <LogoutOutlined onClick={() => logoutHandler()} />
                    &nbsp; Logout
                  </Button>
                </Col>
                {/* <Col span={4}>
                  <Button className="formBtm1 ms-2" htmlType="submit">
                    <UserOutlined
                      onClick={() => setShowProfile(true)}
                      className=" ms-2"
                    />
                  </Button>
                </Col> */}
              </Row>
              <Row>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    onClick={() => _getUserCurrentLocation()}
                    src={flashIcon}
                    style={{
                      height: "28px",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    alt=""
                  />
                  {/* {geoLocationLoader && "Loading..."} */}
                </Col>
                <Col span={22}>
                  <Input
                    readOnly
                    placeholder="My Origin"
                    suffix={<PushpinOutlined />}
                    value={locationSlice?.userGeoLocationInfo?.address}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Drawer>
      </>
    );
  };
  const deleteUser = async () => {
    let userData: any = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    console.log("Deleteing user ....", userData?.id);
    Swal.fire({
      title: "",
      text: "You are about to delete your account.",
      icon: "warning",
      showCloseButton: false,
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete My Account",
    }).then(async (result) => {
      Swal.fire({
        title: "",
        text: " All your information will be removed and you will have to register to use this app again",
        icon: "warning",
        showCloseButton: false,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Delete My Account",
      }).then(async (result) => {
        Swal.fire({
          title: "Please Confirm",
          text: 'Please type "Delete" below to delete your account!',
          input: "text",
          inputPlaceholder: "Type 'Delete' to confirm",
          showCancelButton: true,
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          showLoaderOnConfirm: true,
          preConfirm: (inputValue) => {
            if (inputValue.toLowerCase() !== "delete") {
              Swal.showValidationMessage("Please type 'Delete' to confirm");
            }
            return inputValue;
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const deleteUserApiResp = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/users/${userData?.id}/force`
              );
              console.log("deleteUserApiResp==>", deleteUserApiResp);
              if (
                deleteUserApiResp?.status === 200 ||
                deleteUserApiResp?.status === 204
              ) {
                console.log("User Deleted Successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                localStorage.clear();
                navigate("/");
                toast.success("User Deleted Successfully.");
              }
            } catch (e) {
              console.log("Error deleting user.", e);
              toast.error("Something went wrong");
            }
          }
        });
      });
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        style={{
          position: "absolute",
          zIndex: "99",
          top: "10px",
          left: "10px",
        }}
      >
        <MenuFoldOutlined />
      </Button>
      <UpdatePasswordModal visible={isModalVisible} onCancel={handleCancel} />
      <FilterBar />
    </>
  );
};

export default Dashboard;
