export const openGoogleMap = (orogin: any, destination: any) => {
    const platform = navigator.platform;
    const isiPhone = platform === 'iPhone';
    const isMac = platform === 'MacIntel' || platform === 'MacPPC';
    if (isMac || isiPhone) {
        if (orogin?.lat && orogin?.lng) {
            window.open(`http://maps.apple.com/?saddr=${orogin?.lat},${orogin?.lng}&daddr=${destination?.lat},${destination?.lng}`, '_blank');
        } else {
            window.open(`http://maps.apple.com/?daddr=${destination.lat},${destination?.lng}`, '_blank');
        }
    } else {
        if (orogin?.lat && orogin?.lng) {
            window.open(`https://www.google.com/maps/dir/?api=1&origin=${orogin?.lat},${orogin?.lng}&destination=${destination.lat},${destination?.lng}`, '_blank');
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${destination.lat},${destination?.lng}`, '_blank');
        }
    }

}