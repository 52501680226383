import React from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/slices/authSlice";
export default function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();

  const __forgetPasswordHandler = async (email: any) => {
    const result = await dispatch(forgotPassword(email));
    if (result?.payload?.status === true) {
      navigate("/verify-otp?source=forget-password", { state: { email } });

      alert("Otp Sent via mail. Please verify!");
      messageApi.open({
        type: "success",
        content: "Something went wrong. Try Again!",
      });
    } else {
      alert("Otp Sent via mail. Please verify!");

      messageApi.open({
        type: "error",
        content: result?.payload?.message || "Something went wrong.",
      });
    }
  };
  return (
    <>
      <Modal open={true} closable={false} footer={null} centered>
        {/* <Tabs defaultActiveKey="1" centered items={items} /> */}
        <>
          <Form onFinish={__forgetPasswordHandler} layout="vertical">
            <Row>
              <Col span={6}></Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "This field is required." },
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input placeholder="abc@xyz.com" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6}></Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Button
                  className="formBtm"
                  // disabled={auth?.isLogging}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Modal>
    </>
  );
}
