import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLogging: false,
  isRegistering: false,
  userdata: [],
};

export const login = createAsyncThunk(
  "auth/login",
  async (data: any, thunkAPI: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}login`,
        {
          email: data?.email,
          password: data?.password,
        }
      );

      if (response?.status === 200) {
        return {
          response: response?.data,
          status: true,
          message: "Logged in successfully.",
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "",
  async (data: any, thunkAPI: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/forget-password`,
        {
          email: data?.email,
        }
      );

      if (response?.status === 200) {
        return {
          response: response?.data,
          status: true,
          message: "OTP sent via mail. Please verify Otp!",
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "",
  async (data: any, thunkAPI: any) => {
    try {
      console.log("into slice", data);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/verify-otp`,
        // {
        //   code: data.code.toString(),
        //   email: data.email,
        // }
        data
      );

      if (response?.status === 200) {
        localStorage.setItem("token", response.data.resetToken);
        return {
          response: response?.data,
          status: true,
          message: "OTP sent via mail. Please verify Otp!",
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const registrationThroughEmail = createAsyncThunk(
  "",
  async (data: any, thunkAPI: any) => {
    console.log("Registering user by Email==>", data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/register-by-email`,
        {
          email: data?.email,
        }
      );
      console.log("Response for api call ==>", response);
      if (response?.status === 200 || response?.status === 201) {
        return {
          response: response?.data,
          status: true,
          message: "Otp Sent Successfully",
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const registration = createAsyncThunk(
  "auth/registration",
  async (data: any, thunkAPI: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/register`,
        {
          email: data?.email,
          mobile: data?.mobile,
          password: data?.password,
        }
      );
      if (response?.status === 201) {
        return {
          response: response?.data,
          status: true,
          message: "Registered successfully.",
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLogging = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        const userData = action.payload.response.user;
        state.isLogging = false;
        localStorage.setItem("token", action.payload.response.token);
        localStorage.setItem("userData", JSON.stringify(userData));
      })
      .addCase(login.rejected, (state, action) => {
        state.isLogging = false;
      })
      .addCase(registration.pending, (state) => {
        state.isRegistering = false;
      })
      .addCase(registration.fulfilled, (state, action) => {
        const userData = action.payload.response.record;
        state.isRegistering = false;
        localStorage.setItem("token", action.payload.response.token);
        localStorage.setItem("userData", JSON.stringify(userData));
      })
      .addCase(registration.rejected, (state, action) => {
        state.isRegistering = false;
      });
  },
});

export default authSlice.reducer;
