import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


interface LocationParams {
    latitude: number;
    longitude: number;
}
const initialState = {
    isLoading: false,
    getCurrentLocation: '',
    userGeoLocationInfo: {}
};


export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setUserGeoLocationInfo: (state, action) => {
            state.userGeoLocationInfo = action.payload
        }
    },
    extraReducers: (builder) => {
        // builder
            // .addCase(getGeoLocation.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(getGeoLocation.fulfilled, (state, action) => {
            //     state.isLoading = false;

            //     state.getCurrentLocation = action.payload || ""
            // })
            // .addCase(getGeoLocation.rejected, (state, action) => {
            //     state.isLoading = false;
            // });
    },
});
export const { setUserGeoLocationInfo } = locationSlice.actions;
export default locationSlice.reducer;
