import React, { useState } from "react";
import GoogleMap from "./components/googleMap";
import Auth from "./components/auth";
import Vendors from "./components/vendors";
import Categories from "./components/categories";
import Dashboard from "./components/dashboard";
import Protected from "./utls/protectedRoutes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./utls/interceptor";
import UserAddress from "./components/userAddressForm";
import { useJsApiLoader } from "@react-google-maps/api";
import { Alert } from "antd";
import ForgetPassword from "./components/forget-password";
import VerifyOtp from "./components/verify-otp";
import ResetPassword from "./components/reset-password";
import SetPassword from "./components/new-password";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);

  const { isLoaded: geoLoader, loadError: geoError } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
  });

  return (
    <>
      {isLocationEnabled && (
        <Alert
          closable
          banner
          message="Please enable location service for better results."
          type="warning"
        />
      )}
      <div style={{ position: "relative" }}>
        <BrowserRouter>
          <GoogleMap
            geoLoader={geoLoader}
            dispalyLocationServiceAlert={(state: boolean) =>
              setIsLocationEnabled(state)
            }
          />
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/new-password" element={<SetPassword />} />

            <Route
              path="/brands"
              element={
                <Protected>
                  <Vendors />
                </Protected>
              }
            />
            <Route
              path="/categories"
              element={
                <Protected>
                  <Categories />
                </Protected>
              }
            />
            <Route
              path="/user-address"
              element={
                <Protected>
                  <UserAddress />
                </Protected>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Protected>
                  <Dashboard
                    geoLoader={geoLoader}
                    dispalyLocationServiceAlert={(state: boolean) =>
                      setIsLocationEnabled(state)
                    }
                  />
                </Protected>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
