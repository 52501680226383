import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  message,
  Row,
  Col,
  Checkbox,
  Spin,
  Avatar,
  Steps,
  Divider,
} from "antd";
import type { TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getVendors } from "../../store/slices/vendorSlice";
import { FileImageOutlined } from "@ant-design/icons";
import { updateUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

function Vendors() {
  const dispatch = useDispatch<any>();
  const vendors = useSelector((state: any) => state.vendors);
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    dispatch(getVendors({}));
  }, []);

  const onFinish = async (values: any) => {
    if (!values.vendors) {
      messageApi.open({
        type: "error",
        content: "Select vendors.",
      });
      return;
    }
    const result = await dispatch(updateUser({ vendors: values.vendors }));
    if (result?.payload?.status === 200) {
      messageApi.open({
        type: "success",
        content: "Records updated successfully.",
      });
      setTimeout(() => {
        navigate("/categories");
      }, 2000);
    } else {
      messageApi.open({
        type: "error",
        content: result?.payload?.message || "Something went wrong.",
      });
    }
  };

  const PrintVendors = () => {
    return (
      <>
        {!vendors.isVendorLoading ? (
          <Form
            name="basic"
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <div className="selectionSection vendorsBoxes">
              <Form.Item name="vendors">
                <Checkbox.Group style={{ display: "inline" }}>
                  <Row>
                    {vendors.vendorResults &&
                      vendors.vendorResults.map((e: any, i: any) => (
                        <Col
                          key={`vendors${i}`}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "10px" }}
                        >
                          <div className={`selectionBox`}>
                            <Checkbox value={e.id} style={{ width: "100%" }}>
                              <Avatar
                                size={48}
                                src={
                                  process.env.REACT_APP_BASE_URL?.replace(
                                    /\/api\/v1\/?$/,
                                    "/"
                                  ) + e?.image_path || ""
                                }
                                icon={!e?.image_path && <FileImageOutlined />}
                              />
                              <span
                                className="textWhite"
                                style={{ marginLeft: "10px" }}
                              >
                                {e.name}
                              </span>
                            </Checkbox>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <Row style={{ marginTop: "30px" }}>
              <Col span={6}></Col>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  disabled={user?.isUpdating}
                  style={{ width: "100%" }}
                >
                  {user?.isUpdating ? <Spin /> : "Continue"}
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin />
            <p className="loadingText">Loading Records</p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Modal open={true} closable={false} footer={null} centered>
        <Steps
          // progressDot
          className="custom-steps"
          current={0}
          items={[
            {
              title: "Select Brands",
            },
            {
              title: "Select Categories",
            },
            // {
            //   title: "Enter Address",
            // },
          ]}
        />
        <Divider style={{ borderColor: "#fff" }} />
        <PrintVendors />
      </Modal>
      <div className="authOverlay"></div>
      {contextHolder}
    </>
  );
}

export default Vendors;
