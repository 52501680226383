import { useEffect, useRef, useState } from "react";
import { Button, Modal, Tabs, Form, message, Row, Col, Input, Spin, Avatar, Steps, Divider, FormInstance } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { updateUser, getUserInfo } from "../../store/slices/userSlice";
import { setUserGeoLocationInfo } from '../../store/slices/locationSlice';
import { useNavigate } from 'react-router-dom'; 
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { useLoadScript } from "@react-google-maps/api";
const libraries: any = ["places"];


function UserAddress() {

    const dispatch = useDispatch<any>();
    const fetchLoction = useSelector((state: any) => state.location); 
    
      const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
        libraries
    }); 

    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage(); 
    const lat = localStorage.getItem('lat');
    const lng = localStorage.getItem('lng');
    const formRef = useRef<FormInstance | null>(null); // Add a form reference
    
    const onFinish = async (values: any) => {

        const values2 = formRef.current?.getFieldsValue(true);

        const dataToSend = {
            address: values2.vendors,
            lat: values2.lat ? values2.lat : lat,
            lng: values2.lng ? values2.lng : lng,
          };    
        if(!values2.vendors){
            messageApi.open({
                type: 'error',
                content: 'Enter Address.',
            });
            return;
        }
        const result = await dispatch(updateUser(dataToSend));
        if (result?.payload?.status === 200) {
            messageApi.open({
                type: 'success',
                content: 'Records updated successfully.',
            });
            await dispatch(getUserInfo({}));
            // await dispatch(setUserGeoLocationInfo({
            //   lat: result?.payload?.response?.lat,
            //   lng: result?.payload?.response?.lng,
            //   address: result?.payload?.response?.address
            // }));
            navigate("/dashboard");
        } else {
            messageApi.open({
                type: 'error',
                content: result?.payload?.message || 'Something went wrong.',
            });
        }
    }

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps</div>;
    const PrintVendors = () => {
        const [address, setAddress] = useState<string>('');
        // const formRef = useRef<FormInstance | null>(null); // Add a form reference
      
        const handleSelect = async (address: any) => {
          try {
            const results = await geocodeByAddress(address);
            const selectedPlace = results[0];
            const formattedAddress = selectedPlace.formatted_address;
            const { lat, lng } = await getLatLng(selectedPlace);
      
            formRef.current?.setFieldsValue({ vendors: formattedAddress, lat: 'lat', lng:'lng' }); // Update form values 
            setAddress(formattedAddress);
      
            const values = formRef.current?.getFieldsValue(true);
            if (values) {
              values.lat = lat;
              values.lng = lng;
            }            
          } catch (error) {
            console.error('Error retrieving place details:', error);
          }
        };
      
        const handleChange = (newAddress: string) => {
          setAddress(newAddress);          
          formRef.current?.setFieldsValue({ vendors: newAddress });
        };
      
        return (
          <>
            {/* {fetchLoction?.userGeoLocationInfo?.address ? ( */}
              <Form name="basic" onFinish={onFinish} ref={formRef} layout="vertical">
                <div className="selectionSection vendorsBoxes">
                  <Form.Item name="vendors" initialValue={fetchLoction?.userGeoLocationInfo?.address}>
                    <PlacesAutocomplete
                      value={fetchLoction?.userGeoLocationInfo?.address}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ position: 'relative' }}>
                          <label style={{ color: '#fff' }}>Enter your address</label>
                          <Input
                            {...getInputProps({
                              placeholder: 'Enter your address',
                              className: 'location-search-input',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {/* {loading && <div>Loading...</div>} */}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                //   key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Form.Item>
                </div>
                <Row style={{ marginTop: '30px' }}>
                  <Col span={6}></Col>
                  <Col span={12}>
                    <Button htmlType="submit" disabled={user?.isUpdating} style={{ width: '100%' }}>
                      {user?.isUpdating ? <Spin /> : 'Continue'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            {/* ) : (
              <div style={{ textAlign: 'center' }}>
                <Spin />
                <p className="loadingText">Fetching Address</p>
              </div>
            )} */}
          </>
        );
      };
      

    return (
        <>
            <Modal open={true} closable={false} footer={null} centered>
                <Steps
                    // progressDot
                    className="custom-steps"
                    current={2}
                    items={[
                        {
                            title: 'Select Vendors',
                        },
                        {
                            title: 'Select Categories',
                        },
                        {
                            title: 'Enter Address',
                        }
                    ]}
                />
                <Divider style={{ borderColor: '#fff' }} />
                <PrintVendors />
            </Modal>
            <div className="authOverlay"></div>
            {contextHolder}
        </>
    )
}

export default UserAddress;
