import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isPosting: false,
    postResponse: {},
    isFetching: false,
    polyData: {},
    editableData:{}
};


export const saveRecord = createAsyncThunk('polygon/saveData', async (data: any, thunkAPI:any) => {
    try {
        const coords = data?.coordinates;
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}polygons`, {
            name: data?.name,
            coordinates: JSON.stringify(coords),
            url:data?.url
        })
        if (response?.status === 201) {
            return {
                status: 'success',
                message: 'Record has been saved.'
            };
        }

        return {
            status: 'error',
            message: 'Failed to save records. Please try again.'
        };

    } catch (e: any) {
        return thunkAPI.rejectWithValue({
            status: e.response.status,
            error: e.response.data,
        });
    }
}
);

export const updateRecord = createAsyncThunk('polygon/updateRecord', async (data: any, thunkAPI:any) => {
    try {
        const coords = data?.coordinates;
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}polygons/${data?.id}`, {
            name: data?.name,
            coordinates: JSON.stringify(coords),
            url:data?.url
        })
        if (response?.status === 201 || response?.status === 200) {
            return {
                status: 'success',
                message: 'Record has been saved.'
            };
        }

        return {
            status: 'error',
            message: 'Failed to save records. Please try again.'
        };

    } catch (e: any) {
        return thunkAPI.rejectWithValue({
            status: e.response.status,
            error: e.response.data,
        });
    }
}
);

export const deleteRecord = createAsyncThunk('polygon/deleteRecord', async (key: any, thunkAPI:any) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}polygons/${key}`)
        if (response?.status === 201 || response?.status === 200) {
            return {
                status: 'success',
                message: 'Record has been deleted.'
            };
        }

        return {
            status: 'error',
            message: 'Failed to delete records. Please try again.'
        };

    } catch (e: any) {
        return thunkAPI.rejectWithValue({
            status: e.response.status,
            error: e.response.data,
        });
    }
}
);


export const getRecords = createAsyncThunk('polygon/getRecords', async (data:any=null, thunkAPI) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}polygons`)
        return response;
    } catch (e: any) {
        return thunkAPI.rejectWithValue({
            status: e.response.status,
            error: e.response.data,
        });
    }
}
);


export const polygonSlice = createSlice({
    name: 'polygon',
    initialState,
    reducers: {
        dataReset: (state) => {
            state.isPosting = false;
            state.postResponse = {};
            state.editableData = {};
        },
        setupEditableData: (state, action) => {
            state.editableData = action?.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveRecord.pending, (state) => {
                state.isPosting = true;
            })
            .addCase(saveRecord.fulfilled, (state, action) => {
                state.isPosting = false;
                state.postResponse = action.payload;
            })
            .addCase(saveRecord.rejected, (state, action) => {
                state.isPosting = false;
                state.postResponse = action;
            }).addCase(updateRecord.pending, (state) => {
                state.isPosting = true;
            })
            .addCase(updateRecord.fulfilled, (state, action) => {
                state.isPosting = false;
                state.postResponse = action.payload;
            })
            .addCase(updateRecord.rejected, (state, action) => {
                state.isPosting = false;
                state.postResponse = action;
            }).addCase(getRecords.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(getRecords.fulfilled, (state, action) => {
                state.isFetching = false;
                state.polyData = action.payload;
            })
            .addCase(getRecords.rejected, (state, action) => {
                state.isFetching = false;
                state.polyData = action;
            });
    },
});

export const { dataReset, setupEditableData } = polygonSlice.actions;
export default polygonSlice.reducer;
