import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  message,
  Row,
  Col,
  Checkbox,
  Spin,
  Steps,
  Divider,
  Avatar,
} from "antd";
import type { TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../store/slices/categorySlice";
import { updateUser, getUserInfo } from "../../store/slices/userSlice";
import { FileImageOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function Categories() {
  const dispatch = useDispatch<any>();
  const category = useSelector((state: any) => state.category);
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    dispatch(getCategories({}));
  }, []);

  const onFinish = async (values: any) => {
    if (!values.categories) {
      messageApi.open({
        type: "error",
        content: "Select categories.",
      });
      return;
    }
    const result = await dispatch(
      updateUser({ categories: values.categories })
    );
    if (result?.payload?.status === 200) {
      messageApi.open({
        type: "success", 
        content: "Records updated successfully.",
      });
      await dispatch(getUserInfo({}));
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else {
      messageApi.open({
        type: "error",
        content: result?.payload?.message || "Something went wrong.",
      });
    }
  };

  const onFinishFailed = () => {
    console.log("FORM FAILED");
  };

  const PrintCategories = () => {
    return (
      <>
        {!category.isCategoryLoading ? (
          <Form
            name="basic"
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="selectionSection vendorsBoxes">
              <Form.Item name="categories">
                <Checkbox.Group style={{ display: "inline" }}>
                  <Row>
                    {category.categoryResults &&
                      category.categoryResults.map((e: any, i: any) => (
                        <Col
                          key={`category${i}`}
                          xs={24} sm={12} md={12} lg={12} xl={12}
                          style={{ marginTop: "10px", width: "50%" }}
                        >
                          <div className={`selectionBox`}>
                            <Checkbox value={e.id} style={{ width: "100%" }}>
                              <Avatar
                                size={48}
                                src={
                                  process.env.REACT_APP_BASE_URL?.replace(
                                    /\/api\/v1\/?$/,
                                    "/"
                                  ) + e?.image_path || ""
                                }
                                icon={!e?.image_path && <FileImageOutlined />}
                              />
                              <span
                                className="textWhite"
                                style={{ marginLeft: "10px" }}
                              >
                                {e.name}
                              </span>
                            </Checkbox>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <Row style={{ marginTop: "30px" }}>
              <Col span={6}></Col>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  disabled={user?.isUpdating}
                  style={{ width: "100%" }}
                >
                  {user?.isUpdating ? <Spin /> : "Continue"}
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin />
            <p className="loadingText">Loading Records</p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Modal open={true} closable={false} footer={null} centered>
        <Steps
          className="custom-steps"
          current={1}
          items={[
            {
              title: "Select Brands",
            },
            {
              title: "Select Categories",
            },
            // {
            //   title: "Enter Address",
            // },
          ]}
        />
        <Divider style={{ borderColor: "#fff" }} />
        <PrintCategories />
      </Modal>
      <div className="authOverlay"></div>
      {contextHolder}
    </>
  );
}

export default Categories;
