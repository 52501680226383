import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import type { TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  registration,
  registrationThroughEmail,
} from "../../store/slices/authSlice";
import { setUserGeoLocationInfo } from "../../store/slices/locationSlice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Auth() {
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const auth = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  const __loginHandler = async (values: any) => {
    const result = await dispatch(login(values));
    console.log("Result for login", result);

    try {
      if (result?.payload?.status === true) {
        messageApi.open({
          type: "success",
          content: "Logged in successfully.",
        });
        const userVendors = result.payload.response.user?.Vendors;
        const userCategories = result.payload.response.user?.Categories;
        const userAddress = result.payload.response.user?.address;
        const userLat = result.payload.response.user?.lat;
        const userLng = result.payload.response.user?.lng;

        setTimeout(() => {
          if (userVendors.length === undefined || userVendors.length === 0) {
            navigate("/brands");
          } else if (
            userCategories?.length === undefined ||
            userCategories?.length === 0
          ) {
            navigate("/categories");
          } else if (!userAddress) {
            navigate("/user-address");
          } else {
            navigate("/dashboard");
          }
          // if (userAddress && userLat && userLng) {
          //   dispatch(setUserGeoLocationInfo({
          //     lat: userLat,
          //     lng: userLng,
          //     address: userAddress
          //   }));
          // }
        }, 1000);
      } else {
        messageApi.open({
          type: "error",
          content: result?.payload?.message || "Something went wrong.",
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const __registrationHandler = async (email: any) => {
    // Ask user's age using Swal prompt
    Swal.fire({
      title: "Confirm Age",
      text: "Are you 21 years old or older?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("User is 21 years old or older.");
        // Call the registration API
        console.log("values for registration =>", email);
        const result = await dispatch(registrationThroughEmail(email));
        try {
          if (result?.payload?.status === true) {
            messageApi.open({
              type: "success",
              content: result?.payload?.message,
            });
            if (result?.payload?.message === "Otp Sent Successfully") {
              navigate("/verify-otp?source=register", { state: { email } });
            }
          } else {
            messageApi.open({
              type: "error",
              content:
                result?.payload?.message ||
                result?.payload?.errors[0]?.msg ||
                "Something went wrong.",
            });
          }
        } catch (err) {
          console.log("err", err);
        }
      } else {
        console.log("User is not 21 years old or older.");
        // Handle case where user is not 21 years old or older
        // You can display a message or take any other action here
        Swal.fire({
          icon: "error",
          title: "Underage",
          text: "You must be 21 years old or older to proceed with registration.",
        });
      }
    });
  };

  const Login = () => {
    return (
      <>
        <Form onFinish={__loginHandler} layout="vertical">
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "This field is required." },
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input placeholder="abc@xyz.com" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input.Password placeholder="*********" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div
                onClick={() => navigate("/forget-password")}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "-20px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <p style={{ color: "white" }}>Forgot password</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Button
                className="formBtm"
                disabled={auth?.isLogging}
                htmlType="submit"
              >
                {auth?.isLogging ? <Spin /> : "Login"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const Register = () => {
    return (
      <>
        <Form
          name="basic"
          onFinish={__registrationHandler}
          layout="vertical"
          autoComplete="off"
        >
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "This field is required." },
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input placeholder="abc@xyz.com" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Mobile"
                name="mobile"
                rules={[
                  { required: true, message: "This field is required." },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "The input is not valid.",
                  },
                ]}
              >
                <Input placeholder="1234567890" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input.Password placeholder="***" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Confirm Password"
                name="c_password"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input.Password placeholder="***" />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col span={6}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Button
                className="formBtm"
                disabled={auth?.isRegistering}
                htmlType="submit"
              >
                {auth?.isRegistering ? <Spin /> : "Register"}{" "}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `LOGIN`,
      children: <Login />,
    },
    {
      key: "2",
      label: `REGISTER`,
      children: <Register />,
    },
  ];

  return (
    <>
      <Modal open={true} closable={false} footer={null} centered>
        <Tabs defaultActiveKey="1" centered items={items} />
      </Modal>
      <div className="authOverlay"></div>
      {contextHolder}
    </>
  );
}

export default Auth;
