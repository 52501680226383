import axios from "axios";
let isLoggedOut = false;
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Handle successful response
    console.log("API Response:", response.data);
    return response;
  },
  (error) => {
    // Handle error response
    if (error?.response?.status === 401) {
      if (!isLoggedOut) {
        isLoggedOut = true;
        alert("Your session has been expired. Please login again.");
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        window.location.href = "/";
      }
    } else {
      console.log("NOT KIG");
    }
    return Promise.reject(error);
  }
);
