import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isUpdating: false,
};

export const updateUser = createAsyncThunk(
  "users/update",
  async (data: any, thunkAPI: any) => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "");
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}users/${userData?.id}`,
        data
      );

      if (response?.status === 200) {
        return {
          response: response?.data,
          status: response?.status,
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  "users/update",
  async (data: any, thunkAPI: any) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}users/change-password`,
        data
      );

      if (response?.status === 200) {
        return {
          response: response?.data,
          status: response?.status,
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "users/getOne",
  async (data: any, thunkAPI: any) => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users/${userData?.id}`,
        data
      );
      if (response?.status === 200) {
        return {
          response: response?.data?.record,
          status: response?.status,
        };
      }

      return {
        status: false,
        message: "Failed to save records. Please try again.",
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        const userData = action.payload.response;
        localStorage.setItem("userData", JSON.stringify(userData));
        state.isUpdating = false;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.isUpdating = false;
      });
  },
});

export default userSlice.reducer;
