import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript, DirectionsRenderer, useJsApiLoader } from "@react-google-maps/api";
import { Drawer, Input, Button, Alert } from 'antd';
import googleMapStyle from '../../collections/googleMapStyle.json';
import { useSelector, useDispatch } from "react-redux";
import userMarker from '../../assets/icons/user-location_1.png';
import BogoInfo from "./BogoInfo";
import { setUserGeoLocationInfo } from '../../store/slices/locationSlice';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { type } from "os";
import { openGoogleMap } from "../../utls/helper";

const libraries: any = ["places"];

const mapContainerStyle = {
    width: "100%",
    height: "100vh"
};
interface MarkerData {
    position: { lat: number; lng: number };
    name: string;
    info: any;
}

interface LocationSelectorProps {
    isOpen: boolean;
    onClose: () => void;
}

const GoogleMaps = (props: any) => {
    const { dispalyLocationServiceAlert, geoLoader } = props;
    const dispatch = useDispatch<any>();
    const [markers, setMarkers] = useState<MarkerData[]>([]);
    const [selected, setSelected] = useState<MarkerData | null>(null);
    const [isBogoModalOpen, setIsBogoModalOpen] = useState<boolean>(false);
    const [isAddressSearchOpen, setIsAddressSearchOpen] = useState<boolean>(true);
    const [isLocationServiceOpen, setIsLocationServiceOpen] = useState<boolean>(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isUserDefaultLocationSaved, setIsUserDefaultLocationSaved] = useState<boolean>(false);
    const [selectedBogo, setSelectedBogo] = useState<any>(null);
    const [zoom, setZoom] = useState<number>(13);
    const maxZoom = 15;
    const bogo = useSelector((state: any) => state.bogo);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const locationSlice = useSelector((state: any) => state.location);
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "") : null;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
        libraries,
    });

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {

            permissionStatus.onchange = () => {
                if(permissionStatus.state === 'denied'){
                    dispalyLocationServiceAlert(true);
                    setIsLocationServiceOpen(false);
                }else{ 
                    dispalyLocationServiceAlert(false);
                    setIsLocationServiceOpen(true);
                }
        
            };
        });
    }, [dispalyLocationServiceAlert])

    useEffect(() => {
        // if (!userData) {
        if (geoLoader && map) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        const geocoder = new window.google.maps.Geocoder();
                        const location = new window.google.maps.LatLng(latitude, longitude);
                        geocoder.geocode({ location }, (results, status) => {
                            if (status === 'OK' && results) {
                                dispatch(setUserGeoLocationInfo({
                                    lat: latitude,
                                    lng: longitude,
                                    address: results[0].formatted_address,
                                }))
                            } else {
                                console.error('Geocoder failed:', status);
                            }
                        });
                    },
                    (error) => {
                        setIsLocationServiceOpen(false);
                        dispalyLocationServiceAlert(true);
                    }
                );
            }

        }
        // } else {
        //     if (!isUserDefaultLocationSaved) {
        //         dispatch(setUserGeoLocationInfo({
        //             lat: userData?.lat,
        //             lng: userData?.lng,
        //             address: userData?.address
        //         }));
        //         setIsUserDefaultLocationSaved(true);
        //     }

        // }
    }, [geoLoader, map, dispalyLocationServiceAlert, dispatch]);


    useEffect(() => {
        setMarkers([])
        const newMarkers: any = [];
        bogo.bogoResults && bogo.bogoResults.forEach((e: any) => {
            newMarkers.push({
                position: {
                    lat: e?.Location?.lat,
                    lng: e?.Location?.lng,
                },
                name: e?.Location?.name,
                info: e
            })
        })
        setTimeout(() => {
            setMarkers(newMarkers)
        },)

    }, [bogo.bogoResults]);

    useEffect(() => {
        if (map) {
            const bounds = new window.google.maps.LatLngBounds();

            if (locationSlice?.userGeoLocationInfo?.lat && locationSlice?.userGeoLocationInfo?.lng) {
                bounds.extend(new window.google.maps.LatLng(Number(locationSlice?.userGeoLocationInfo?.lat), Number(locationSlice?.userGeoLocationInfo?.lng)));
            }

            // if (userData?.lat && userData?.lng) {
            //     bounds.extend(new window.google.maps.LatLng(Number(userData?.lat), Number(userData?.lng)));
            // } else if (locationSlice?.userGeoLocationInfo?.lat && locationSlice?.userGeoLocationInfo?.lng) {
            //     bounds.extend(new window.google.maps.LatLng(Number(locationSlice?.userGeoLocationInfo?.lat), Number(locationSlice?.userGeoLocationInfo?.lng)));
            // }

            for (let i = 0; i < markers.length; i++) {
                bounds.extend(new window.google.maps.LatLng(markers[i].position.lat, markers[i].position.lng));
            }
            map && map.fitBounds(bounds);
            const currentZoom = map.getZoom()!;
            if (currentZoom > maxZoom) {
                map.setZoom(maxZoom);
            }
        }
    }, [map, markers, locationSlice, userData, locationSlice?.userGeoLocationInfo?.lat, locationSlice?.userGeoLocationInfo?.lng]);

    useEffect(() => {
        setResponse(null);
    }, [bogo?.isBogoLoading])

    const handleMarkerClick = (marker: MarkerData) => {
        setSelected(marker);
        setIsBogoModalOpen(true);
    };

    const [response, setResponse] = useState(null);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps</div>;



    const LogocationSelector: React.FC<LocationSelectorProps> = ({ isOpen, onClose }) => {
        const [address, setAddress] = useState<any>(null);

        const directionsCallback = (res: any) => {
            if (res !== null) {
                setResponse(res);
            }
            handleDrawerClose();
        }
        const provideDirection = async (): Promise<void> => {
            try {

                // let fromLat = null;
                // let toLng = null;
                if (address) {

                    const results = await geocodeByAddress(address);
                    const latLng = await getLatLng(results[0]);

                    if (latLng) {
                        // fromLat = latLng.lat;
                        // toLng = latLng.lng;

                        dispatch(setUserGeoLocationInfo({
                            lat: latLng.lat,
                            lng: latLng.lng,
                            address: address
                        }));
                        openGoogleMap({lat:latLng?.lat, lng:latLng?.lng},{lat:selectedBogo?.Location?.lat, lng:selectedBogo?.Location?.lng})
                        // window.open(`https://www.google.com/maps/dir/?api=1&origin=${latLng?.lat},${latLng?.lng}&destination=${selectedBogo?.Location?.lat},${selectedBogo?.Location?.lng}`, '_blank');
                    }
                }else{
                    openGoogleMap(null,{lat:selectedBogo?.Location?.lat, lng:selectedBogo?.Location?.lng})
                    // window.open(`https://www.google.com/maps/dir/?api=1&destination=${selectedBogo?.Location?.lat},${selectedBogo?.Location?.lng}`, '_blank');
                }

                // const directionsService = new google.maps.DirectionsService();
                // directionsService.route(
                //     {
                //         origin: { lat: Number(fromLat), lng: Number(toLng) },
                //         destination: { lat: selectedBogo?.Location?.lat, lng: selectedBogo?.Location?.lng },
                //         travelMode: google.maps.TravelMode.DRIVING,
                //     },
                //     directionsCallback
                // );
            } catch (error) {
                console.error('Error:', error);
            }
        };


        const handleChange = (newAddress: string) => {
            setAddress(newAddress);
        };
        return (
            <>
                <Drawer placement={'left'} maskClosable={true} onClose={onClose} open={isOpen}>
                    <PlacesAutocomplete
                        value={address || (isLocationServiceOpen && locationSlice?.userGeoLocationInfo?.address) || ''}
                        onChange={handleChange}
                    // onSelect={handleSelectPlace}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{ position: 'relative' }}>
                                <label style={{ color: '#fff' }}>Origin</label>
                                <Input
                                    {...getInputProps({
                                        placeholder: 'Enter your address',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <div style={{ marginTop: '10px' }}></div>
                    <label style={{ color: '#fff' }}>Destination</label>
                    <Input
                        placeholder="Choose destination point"
                        // suffix={<SearchOutlined />}
                        value={selectedBogo?.Location?.address}
                    />
                    <Button style={{ marginTop: '10px', float: 'right' }} className="mt-3" onClick={() => provideDirection()}>Let's Go</Button>
                </Drawer>
            </>
        );
    };

    const onLoad = (map: google.maps.Map) => {
        setMap(map);
    };

    // const redirectToGoogleTab = async (destination: any) => {
    //     // const geocoder = await new window.google.maps.Geocoder();
    //     // let location = await new window.google.maps.LatLng(destination?.Location?.lat, destination?.Location?.lng);
    //     // const destinationInfo = { destination: '', destinationPlaceId: '' };
    //     // await geocoder.geocode({ location }, (results, status) => {
    //     //     if (status === 'OK' && results) {
    //     //         destinationInfo.destination = results[0]?.formatted_address
    //     //         destinationInfo.destinationPlaceId = results[0]?.place_id;
    //     //     } else {
    //     //         console.error('Geocoder failed:', status);
    //     //     }
    //     // });

    //     // location = await new window.google.maps.LatLng(Number(locationSlice?.userGeoLocationInfo?.lat), Number(locationSlice?.userGeoLocationInfo?.lng));
    //     // const originInfo = { origin: '', originPlaceId: '' };
    //     // await geocoder.geocode({ location }, (results, status) => {
    //     //     if (status === 'OK' && results) {
    //     //         originInfo.origin = results[0]?.formatted_address
    //     //         originInfo.originPlaceId = results[0]?.place_id;
    //     //     } else {
    //     //         console.error('Geocoder failed:', status);
    //     //     }
    //     // });

    //     setTimeout(() => {
    //         window.open(`https://www.google.com/maps/dir/?api=1&origin=${locationSlice?.userGeoLocationInfo?.lat},${locationSlice?.userGeoLocationInfo?.lng}&destination=${destination?.Location?.lat},${destination?.Location?.lng}`, '_blank');
    //         // window.open(`https://www.google.com/maps/dir/?api=1&origin=${originInfo.origin}&origin_place_id=${originInfo.originPlaceId}&destination=${destinationInfo.destination}&destination_place_id=${destinationInfo.destinationPlaceId}`, '_blank');
    //     }, 100)

    // }

    return (
        <>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                options={{
                    styles: googleMapStyle,
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDefaultUI: true,
                    keyboardShortcuts: false,
                    gestureHandling: "greedy",
                }}
                onLoad={onLoad}
            >
                <Marker
                    key={`userlocation`}
                    position={{ lat: Number(locationSlice?.userGeoLocationInfo?.lat), lng: Number(locationSlice?.userGeoLocationInfo?.lng) }}
                    draggable={false}
                    icon={userMarker}
                />
                {response !== null && <DirectionsRenderer options={{ suppressMarkers: true }} directions={response} />}
                {markers.map((marker, index) => (
                    <Marker
                        key={`${marker.position.lat}-${marker.position.lng}-${index}`}
                        position={marker.position}
                        onClick={() => handleMarkerClick(marker)}
                        draggable={false}
                        icon={marker?.info?.Location?.is_marker_allowed ? process.env.REACT_APP_BASE_URL?.replace(/\/api\/v1\/?$/, '/') + marker?.info?.Location?.marker_icon : undefined}
                    />
                ))}
                <LogocationSelector isOpen={isDrawerOpen} onClose={handleDrawerClose} />
            </GoogleMap>
            <BogoInfo
                isOpen={isBogoModalOpen}
                selectedMarker={selected?.info}
                handleDrawerOpen={handleDrawerOpen}
                isLocationServiceOpen={isLocationServiceOpen}
                closeBogoModal={() => setIsBogoModalOpen(false)}
                openAddressSearch={(selectedInfo: any) => { setIsAddressSearchOpen(true); setSelectedBogo(selectedInfo); }}
            />
        </>
    )
}

export default GoogleMaps;